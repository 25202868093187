import React, { useRef, useMemo } from "react";
import $ from 'jquery';
import "../../css/index.css"
import { Canvas, useFrame } from 'react-three-fiber'
import niceColors from 'nice-color-palettes'
import * as THREE from 'three'

import Labeling from "../../components/labeling"
import NavigationBar from "../../components/navigation"

import Thesis from "../../assets/images/home/thesis.png"
import DesignSystem from "../../assets/images/home/ds.png"
// import Vogether from "../../assets/images/vogether/2-record.gif"
import ProductStudio from "../../assets/images/home/ProductStudio.svg"
import Voice from "../../assets/images/home/voice.svg"
import PinkButton from "../../components/pinkButton"

import Github from "../../assets/images/contact/github.svg"
import Linkedin from "../../assets/images/contact/linkedin.svg"


const windowHeight = {
    height: window.innerWidth < 990 ? window.innerHeight * 1.2 : window.innerHeight,
    minHeight: 600,
    position: 'relative',
}


const tempObject = new THREE.Object3D()
const tempColor = new THREE.Color()
const colors = new Array(1000).fill().map(() => niceColors[13][Math.floor(Math.random() * 5)])


function Boxes() {
    const colorArray = useMemo(() =>
        Float32Array.from(new Array(1500).fill().flatMap((_, i) => tempColor.set(colors[i]).toArray())), []
    )

    const ref = useRef()
    useFrame(state => {
        const time = state.clock.getElapsedTime()
        ref.current.position.x = Math.sin(time / 8) * 2
        ref.current.position.y = Math.sin(time / 2)
        ref.current.rotation.x += .002
        ref.current.rotation.y += .002

        let i = 0
        for (let x = 0; x < 10; x++)

            for (let y = 0; y < 10; y++)

                for (let z = 0; z < 10; z++) {
                    const id = i++
                    tempObject.position.set(5 - x, 5 - y, 5 - z)

                    tempObject.position.multiplyScalar(Math.sin(time) / 10 + 1.2)

                    tempObject.updateMatrix()
                    ref.current.setMatrixAt(id, tempObject.matrix)
                }
        ref.current.instanceMatrix.needsUpdate = true
    })

    return (
        <instancedMesh ref={ref} args={[null, null, 1000]}>
            <sphereBufferGeometry args={[.3, 30, 30]} attach="geometry" >
                <instancedBufferAttribute attachObject={['attributes', 'color']} args={[colorArray, 3]} />
            </sphereBufferGeometry>
            <meshPhongMaterial attach="material" vertexColors={THREE.VertexColors} />
        </instancedMesh>
    )
}


class Homepage extends React.Component {

    state = {
        top: 300,
        left: 300,
        cursorImg: ' ',
        cursorRotation: 'rotate(0deg)',
        password: '0',
        background: 0,
        timeLineHeight: $(document).height()
    }

    render() {
        return (


            <div id="parallaxScroll" >

                {/* navigation bar */}
                <NavigationBar href="#contactPart" contact />
                <div id="HomapageTopNavi" style={{ backgroundColor: ' linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))' }}></div>

                <div id="HP_container" className='black HP_container' >



                    {/* landing page */}
                    <div style={windowHeight} className="black sessionContainer" >
                        <div id="landingPart" >
                            <div className='landingpage_Intro animate__animated animate__fadeInLeft'>

                            I design, test, and iterate based on research.

                            </div>


                        </div>
                        <Canvas
                            style={{ zIndex:1, position: 'fixed', right: '0px', top: '0px', width: window.innerWidth > 768 ? "60%" : "100%", zIndex: 1, }}
                            camera={{ position: [3, 5, 15] }}
                        >
                            <ambientLight color="#FFFFFF" />
                            <pointLight position={[150, 150, 150]} intensity={1} />
                            <Boxes />
                        </Canvas>
                    </div>


                    {/* Desigin system */}
                    <div id="Design-system" className="black sessionContainer reverse bg-white" style={windowHeight}>

                        <div className="contentblock">
                            <Labeling
                                time="Design case study"
                                color='black'
                            />
                            <h1>Building an enterprise design system</h1>
                            <p>This case study presents my experience and process in handling complex, big-scale, high-stakes design challenges that involve a broad spectrum of stakeholders.
                                <br />
                                <PinkButton
                                    innerLink={true}
                                    label="Read details"
                                    link="design-system"

                                />
                            </p>
                        </div>
                        <div className="imgBlock">
                            <img src={DesignSystem} alt="design system" className="ds img" />
                        </div>
                    </div>




                    {/* Voice */}
                    <div id="Voice" className="black sessionContainer bg-purple" style={windowHeight}>

                        <div className="contentblock">
                            <Labeling
                                time="Design case study"
                                color='black'
                            />
                            <h1>Designing an NFT creating feature for non-technical users</h1>
                            <p>This case study demonstrates my process in a general product development setting.
                                <br />
                                <PinkButton
                                    innerLink={true}
                                    label="Read more"
                                    link="voice"

                                />
                            </p>
                        </div>
                        <div className="imgBlock">
                            <img src={Voice} alt="voice" className="img" />
                        </div>
                    </div>



                    {/* product studio */}
                    <div id="ProductStudio" className="black sessionContainer reverse bg-smokewhite" style={windowHeight} >
                        <div className="contentblock">
                            <Labeling
                                time="Product research"
                                color="black"
                            />
                            <h1>How to hire millennial technical talents</h1>
                            <p>
                                This is a four-month product design challenge given by the US Department of Defense when I joined product studio as a designer at Cornell Tech.
                                <br />
                                <PinkButton
                                    innerLink={true}
                                    label="See process"
                                    link="product-studio"
                                />
                            </p>
                        </div>
                        <div className="imgblock">
                            <img src={ProductStudio} className="DODCornellImg" alt="ProductStudio"></img>
                        </div>
                    </div>


                    {/* Thesis Campy */}
                    <div id="Thesis" className="black sessionContainer bg-yellow" style={windowHeight}>

                        <div className="contentblock">
                            <Labeling
                                time="Passion projects"
                                color='black'
                            />
                            <h1>Researching the future of technology and humanity.</h1>
                            <p>This is my one-year research and self-exploration journey, presented in multi-media formats, ultimately defining my passion and interest.
                                <br />
                                <PinkButton
                                    innerLink={true}
                                    label="See works"
                                    link="thesis"

                                />
                            </p>
                        </div>
                        <div className="imgBlock">
                            <img src={Thesis} alt="thesis" className="campy img" />
                        </div>
                    </div>
                    {/* See more */}
                    <div id="seeMore" className="black sessionContainer bg-coral" style={{ height: 450, position: 'relative' }} >
                        <div style={{ width: '90%', maxWidth: 480, marginLeft: 'auto', marginRight: 'auto' }}>
                            <h1>Haven’t seen enough?</h1>
                            <p>
                                Click below if you’d like to see more of my coding and writing work. You will find them in my project list.
                                <br />
                                <PinkButton
                                    innerLink={true}
                                    label="See more projects"
                                    link="contact"
                                />
                            </p>
                        </div>
                    </div>


                    {/* contact */}
                    <div style={windowHeight} id="Contact" className="sessionContainer contactPart">
                        <div id="contactPart">

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                <a href="https://github.com/isabellawang0108" style={{ marginRight: '36pt' }}>
                                    <img style={{ width: '36pt' }} src={Github} alt="icon"></img>
                                </a>

                                <a href="https://www.linkedin.com/in/isabella-wang-310181149/">
                                    <img style={{ width: '36pt' }} src={Linkedin} alt="icon"></img>
                                </a>
                            </div>
                            <div className="black">
                                <h2> wangxbella0108@gmail.com</h2>
                                <h2> +1 (908) 391 – 6750</h2>
                            </div>
                        </div>
                    </div>



                </div>

            </div >
        );

    }
}

export default Homepage;
